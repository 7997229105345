(function(window){
    function TeacherSlider(container, params) {
        try {
            if (!container) {
                throw 'Container is required!'
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    TeacherSlider.prototype = {
        container: undefined,
        sliderContainer: undefined,
        scrollContent: undefined,
        memberBoxes: undefined,
        textContent: undefined,
        membersContainer: undefined,
        pagerNext: undefined,
        pagerPrev: undefined,
        containerWidth: 0,
        slideBreakpoint: 1024,
        init: function() {
            this.setElements();
            this.setContainerWidth();
            this.setBoxSizes();
            this.addDOMEvents();
        },
        setElements: function() {
            this.sliderContainer = this.container.find('[data-purpose="slider-container"]');
            this.scrollContent = this.container.find('[data-purpose="scrollable-content"]');
            this.textContent = this.container.find('[data-purpose="text-content"]');
            this.memberBoxes = this.container.find('[data-purpose="teacher-box"]');
            this.membersContainer = this.container.find('[data-purpose="teacher-container"]');
            this.pagerNext = this.container.find('[data-purpose="next-pager"]');
            this.pagerPrev = this.container.find('[data-purpose="prev-pager"]');
        },  
        addDOMEvents: function() {
            var _self = this;
            
            this.pagerNext.find('.svg').click(function(){
                _self.slide(-1);
            });

            this.pagerPrev.find('.svg').click(function(){
                _self.slide(1);
            });

            $(document).on('mesekert.window_width_changed', function(e, newWindowWidth){
                _self.setContainerWidth();
                _self.getInnerSize();
                
                if (newWindowWidth >= _self.slideBreakpoint) {
                    _self.slide(0);
                }
            });
        },
        setParams: function(params) {
            console.log(params);
        },
        setBoxSizes: function() {
            var width = this.getInnerSize();

            if (width > windowWidth) {
                this.pagerNext.addClass('active');
            }
            if (windowWidth >= 1024) {
                this.scrollContent.width(width);
            } else {
                this.scrollContent.removeAttr('style');
            }  
        },
        slide: function(direction) {
            var innerWidth = this.getContainerWidth();
            var boxWidth = this.getBoxWidth();
            
            var stepSize = Math.floor(innerWidth / boxWidth) * boxWidth;
            var currentSlide = parseInt(this.scrollContent.data('slide-value'));
            var contentWidth = this.getSliderContentWidth();

            if (isNaN(currentSlide)) {
                currentSlide = 0;
            }
            
            currentSlide += (direction * stepSize);
            
            if (currentSlide >= 0 || innerWidth > contentWidth) {
                currentSlide = 0;
                if (this.pagerPrev.hasClass('active')) {
                    this.pagerPrev.removeClass('active');
                }
            }
            
            if (contentWidth <= Math.abs(currentSlide)+innerWidth && contentWidth > innerWidth) {
                currentSlide = -1 * contentWidth + innerWidth;
                if (this.pagerNext.hasClass('active')) {
                    this.pagerNext.removeClass('active');
                }
            } else {
                if (!this.pagerNext.hasClass('active') && innerWidth < contentWidth) {
                    this.pagerNext.addClass('active');
                }
            }

            if (currentSlide < 0) {
                if (!this.pagerPrev.hasClass('active')) {
                    this.pagerPrev.addClass('active');
                }
            }
            
            if (windowWidth >= this.slideBreakpoint) {
                var additional = 0;

                this.scrollContent.css({
                    'transform': 'translateX('+ (currentSlide + additional) +'px)',
                    'width':contentWidth+'px'
                });
            } else {
                this.scrollContent.css({
                    'transform': 'translateX(0px)',
                });
            }

            this.scrollContent.data('slide-value', currentSlide);
        },
        getInnerSize: function() {
            var width = this.textContent.outerWidth(true);
            var membersWidth = 0;
            var boxWidth = this.getBoxWidth();
            
            this.memberBoxes.each(function(){
                membersWidth += $(this).outerWidth(true);
            });
            
            this.membersContainer.width(membersWidth);
            width += membersWidth;
            
            this.sliderWidth = width;
            return width;
        },
        getBoxWidth: function() {
            if (typeof windowWidth !== undefined) {
                
            }
            
            return 246;
        },
        setContainerWidth: function() {
            this.containerWidth = this.sliderContainer.outerWidth();
        },
        getContainerWidth: function() {
            return this.containerWidth;
        },
        getSliderContentWidth: function() {
            return this.sliderWidth;
        },
    };

    window.TeacherSlider = TeacherSlider;
})(window);
